<template>
  <!-- 底部信息 -->
  <div class="footer">
    <div class="footerCon">
      <div class="footer_title">
        <div class="footer_wl_l"></div>
        <div class="footer_t">联系我们</div>
        <div class="footer_wl_r"></div>
      </div>
      <div class="social_contact_list">
        <div class="sc_li">
          <div class="sc_li_code">
            <img src="@/assets/img/eqcode_wc.jpg" alt />
          </div>
          <div class="sc_li_title">微信订阅号</div>
        </div>
        <div class="sc_li">
          <div class="sc_li_code">
            <img src="@/assets/img/eqcode_video.jpg" alt />
          </div>
          <div class="sc_li_title">微信视频号</div>
        </div>
        <div class="sc_li">
          <div class="sc_li_code">
            <img src="@/assets/img/eqcode_dy.jpg" alt />
          </div>
          <div class="sc_li_title">抖音号</div>
        </div>
        <div class="sc_li">
          <div class="sc_li_code">
            <img src="@/assets/img/eqcode_wb.jpg" alt />
          </div>
          <div class="sc_li_title">微博号</div>
        </div>
      </div>
      <div class="copy_box">
        <div class="copy_spc">
          <p class="copyRight">版权所有©2014-2023 信广龙</p>
          <p class="des">
            网站备案/许可证号：
            <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备15008562号</a>
          </p>
        </div>
        <div class="company_name">企业邮箱：xgl@szxgl.cn</div>
      </div>
    </div>
  </div>
</template>

<script>



</script>
 
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 733px;
  background: #fff;
  overflow: hidden;

  .footerCon {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .footer_title {
      padding-top: 125px;
      display: flex;
      justify-content: center;
      flex-flow: nowrap;
      position: relative;
      align-items: center;

      .footer_t {
        font-size: 60px;
        font-weight: 700;
        color: #ff9900;
        margin: 0 20px;
      }

      .footer_wl_l {
        width: 123px;
        height: 31px;
        background: url(../assets/img/p2_title_wl.png);
        background-size: 100%;
        background-repeat: no-repeat;
      }

      .footer_wl_r {
        width: 123px;
        height: 31px;
        background: url(../assets/img/p2_title_wl.png);
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }

    .social_contact_list {
      width: 1262px;
      height: 250px;
      margin: 0 auto;
      margin-top: 97px;
      // background: #ff9900;
      display: flex;
      flex-wrap: nowrap;
      align-content: space-around;
      justify-content: space-between;

      .sc_li {
        width: 201px;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: inherit;

        .sc_li_code {
          width: 201px;
          height: 201px;
          border: 1px solid #383636;
          border-radius: 9px;
          padding: 5px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .sc_li_title {
          margin-top: 27px;
          font-size: 24px;
        }
      }
    }

    .copy_box {
      width: 100%;
      height: 189px;
      background: #e9e8e9;
      // margin-top: 97px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 0 155px;
      box-sizing: border-box;
      align-content: center;
      align-items: center;

      .copy_spc {
        display: flex;
        flex-direction: column;

        .copyRight,
        .des {
          font-size: 22px;

          a {
            color: #000;
          }
        }
      }

      .company_name {
        font-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .footer {
    height: 100%;
  }

  .footerCon {}

  .footer_t {
    padding: 100px 0px;
    font-style: 76px !important;
  }

  .social_contact_list {
    width: 1600px !important;
    height: 350px;
    padding: 80px 0px;
  }

  .copy_box {
    width: 100%;
    height: 295px;
    font-size: 28px !important;
    padding: 0px;
    margin-top: 0px;

    .copy_spc {
      font-size: 28px !important;

      .copyRight {
        font-size: 28px !important;
      }
    }
  }
}

.sc_li {
  width: 281px !important;
}

.sc_li_code {
  width: 281px !important;
  height: 281px !important;
}
</style>