<template>
  <div class="navbar">
    <!-- 顶部菜单栏 -->
    <div class="navbar_container">
      <div class="logo_box">
        <img class="logo" src="@/assets/img/logo.png" @click="this.$router.push('/')" />
      </div>
    </div>
  </div>
</template>

<script>



</script>
 
<style lang="scss" scoped>
/* 移动端样式： 屏幕大于 750样式 */
@media screen and (min-width: 751px) {
  .navbar {
    width: 100%;
    height: 95px;
    background: rgba(255, 255, 255, 1);
    box-sizing: border-box;

    .navbar_container {
      width: 85%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      .logo_box {
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .logo {
          height: 60%;
        }
      }

      .news {
        color: #090919;
        font-size: 22px;
      }
    }
  }
}

/* 移动端样式： 屏幕小于 750样式 */
@media screen and (max-width: 750px) {
  .navbar {
    width: 100%;
    height: 155px;
    background: rgba(255, 255, 255, 1);
    box-sizing: border-box;

    .navbar_container {
      width: 80%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      .logo_box {
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .logo {
          height: 90%;
        }
      }

      .news {
        color: #090919;
        font-size: 22px;
      }
    }
  }
}
</style>